import Topbar from "../../components/organisms/topbar"
import React from "react"
import CustomLayout from "../../Layouts/Layout"

import "./styles.scss"
import SuperListedImageManagement from "../../domain/superListedImageManagement"

const index = ({ location }) => {
  return (
    <div className="customContent">
      <Topbar />
      <CustomLayout
        selectedPage={<SuperListedImageManagement location={location} />}
      />
    </div>
  )
}

export default index
