/* eslint-disable react/no-unknown-property */
import React, { useEffect, useState, useRef } from "react"
import ImgCrop from "antd-img-crop"
import {
  CheckCircleTwoTone,
  CopyTwoTone,
  FilterOutlined,
} from "@ant-design/icons"
import {
  Tooltip,
  Button,
  Image,
  Modal,
  Space,
  Typography,
  Upload,
  Spin,
  Form,
  Input,
  Popconfirm,
} from "antd"
import InfiniteScroll from "react-infinite-scroll-component"

import "./style.module.scss"
import { openNotificationWarning } from "../../../components/notification"
import { getLitImageByStoreId, uploadImages } from "../../../services/user"
import { deleteImages } from "../../../services/apiProduct"
import folder from "../../../images/image_folder.png"
import close from "../../../images/close.png"
import { LazyLoadComponent } from "react-lazy-load-image-component"

import { deleteAllImageOfStore } from "../../../services/product"
import NoImg from "../../../images/no_image.jpg"

const { Text } = Typography
const initialVisitableModal = {
  status: false,
  item: undefined,
}

const ModalImage = ({ storeId, store }) => {
  const [isModal, setIsModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataImage, setDataImage] = useState([])

  const [fileList, setFileList] = useState([])
  const [pass, setPass] = useState(false)
  const [isCopy, setIsCopy] = useState({})
  const [visibleModal, setVisibleModal] = useState(initialVisitableModal)
  const [hasMore, setHasMore] = useState(true)
  const [haveNoData, setHaveNoData] = useState(false)
  const [searchParams, setSearchParams] = useState(null)
  const [form] = Form.useForm()

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 30,
    },
  })
  const ref = useRef()

  const beforeUpload = (file, info) => {
    console.log("file", file)
    const isJPG = file.type === "image/jpeg"
    const isJPEG = file.type === "image/jpeg"
    const isGIF = file.type === "image/gif"
    const isPNG = file.type === "image/png"
    if (!(isJPG || isJPEG || isGIF || isPNG)) {
      openNotificationWarning(
        "間違ったファイル形式。",
        `JPG、JPEG、GIF、PNGファイルをアップロードしてください。`,
        "#f81d22"
      )

      setPass(false)
      return false
    }
    setPass(true)
    return true
  }

  const uploadImageF = async (options) => {
    const { onSuccess, onError, file, onProgress, category } = options

    const fmData = new FormData()

    fmData.append("files", file)
    if (pass) {
      try {
        const { response } = await uploadImages(
          {
            storeId: storeId,
          },
          fmData
        )
        console.log("resresresres", response)

        if (response?.status == 200) {
          onSuccess("ok", response)
          setFileList([])

          listImageOrigin()
        } else {
          onError("fail")
          setFileList([])
        }
      } catch (err) {
        console.log("Eroor: ", err)
        onError({ err })
      }
    }
  }

  const handleChange = async (info) => {
    console.log("infoinfo", info)
    let fileList = [...info.fileList]

    if (fileList.length == 0) {
      setFileList([...fileList])
    }
    if (pass) {
      fileList = fileList.slice(-1)
      setFileList(fileList)
    }
  }

  const handleOkModalRemove = async () => {
    setLoading(true)
    const { data } = await deleteImages({
      imageUrl: visibleModal.item?.url,
      imageId: visibleModal.item?.id,
      type: "store",
    })
    setLoading(false)
    if (data) {
      listImageOrigin()
    }
    setVisibleModal(initialVisitableModal)
  }

  const fetchMoreData = () => {
    // a fake async api call like which sends
    setTimeout(async () => {
      const maxCounter = tableParams.pagination.current + 1

      const { response, data } = await getLitImageByStoreId({
        store_id: storeId,
        offset:
          (tableParams?.pagination?.current + 1 - 1) *
          tableParams?.pagination?.pageSize,
        limit: tableParams?.pagination?.pageSize,
        q: searchParams ? searchParams : null,
      })
      // //console.log('response message', response);
      if (response.status == 200) {
        if (data) {
          if (
            data?.total &&
            Math.ceil(data?.total / 30) <= tableParams?.pagination?.current + 1
          ) {
            setHasMore(false)
          }
          setDataImage(dataImage?.concat(data?.data))
          setTableParams({
            pagination: {
              current: maxCounter,
              pageSize: 30,
            },
          })
        }
      }
    }, 250)
  }

  const listImageOrigin = async () => {
    setHaveNoData(false)
    setLoading(true)

    setHasMore(true)
    const dataSent = {
      store_id: storeId,
      offset: 0,
      limit: tableParams.pagination.pageSize,
      q: searchParams ? searchParams : null,
    }
    const { response, data } = await getLitImageByStoreId(dataSent)

    if (response.status == 200) {
      setLoading(false)
      const scrollIntoView = document.getElementById("idTop")
      console.log("scrollIntoView", scrollIntoView)
      scrollIntoView?.scrollIntoView()
      setDataImage(data.data)
      setTableParams({
        pagination: {
          current: 1,
          pageSize: 30,
        },
      })
      if (data?.total && Math.ceil(data?.total / 30) <= 1) {
        setHasMore(false)
      }
      if (data?.data?.length == 0) {
        setHaveNoData(true)
      }
    }
  }

  useEffect(() => {
    if (isModal) {
      listImageOrigin()
    }
  }, [searchParams, isModal])

  const handleOpen = () => {
    setIsModal(true)
  }

  const handleCancel = () => {
    setTableParams({
      pagination: {
        current: 1,
        pageSize: 30,
      },
    })
    setDataImage(null)
    setHasMore(true)

    setIsModal(false)
  }
  // console.log("setHasMore", hasMore)
  const onFinish = async (value) => {
    setSearchParams(value?.keyword)
  }
  const deleteAllImage = async () => {
    const { response } = await deleteAllImageOfStore({ storeId: storeId })
    if (response.status == 200) {
      listImageOrigin()
    }
  }
  return (
    <>
      <div
        style={{
          cursor: "pointer",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          borderBottom: "1px solid #888888",
          marginRight: "24px",
        }}
        onClick={handleOpen}
      >
        <Image
          style={{
            width: "60px",
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
          }}
          preview={false}
          src={folder}
        />
        <Tooltip title={store.name}>
          <Typography className="wrapRow">{store?.name}</Typography>
        </Tooltip>
      </div>
      <Modal
        title="画像管理"
        centered
        visible={isModal}
        onCancel={handleCancel}
        width={800}
        footer={[
          [
            <Button key="back" onClick={handleCancel}>
              キャンセル
            </Button>,
            <Tooltip
              title="この操作を実施すると、回復できません。続けてもよろしいでしょうか。"
              key="delete"
            >
              <Popconfirm
                title="画像フォルダを削除してもよろしいでしょうか？"
                onConfirm={deleteAllImage}
                okText="削除"
                cancelText="キャンセル"
                placement="bottomRight"
                disabled={dataImage?.length == 0}
              >
                <Button
                  type="primary"
                  disabled={dataImage?.length == 0}
                  className="ml-[10px]"
                >
                  一括削除
                </Button>
              </Popconfirm>
            </Tooltip>,
          ],
        ]}
      >
        <div className={`min-h-[300px] max-h-[500px]  modalImg`}>
          <Space style={{ justifyContent: "space-between", width: "100%" }}>
            <ImgCrop
              aspect={14 / 9}
              // grid
              // rotate
              rotationSlider
              modalTitle="画像編集"
              modalOk="選択"
              modalCancel="キャンセル"
            >
              <Upload
                customRequest={uploadImageF}
                fileList={fileList}
                beforeUpload={beforeUpload}
                onChange={handleChange}
                maxCount={1}
              >
                <Button
                  danger
                  icon={
                    <Image
                      alt=""
                      src={"/icon_image.svg"}
                      preview={false}
                      className="pr-2"
                    />
                  }
                  className="!text-primary-90 !flex items-center !w-[175px] !h-[37px]"
                >
                  画像をアップロード
                </Button>
              </Upload>
            </ImgCrop>

            <Form
              form={form}
              colon={false}
              name="validate_other"
              // style={{ paddingTop: 10 }}
              initialValues={{}}
              onFinish={onFinish}
              onValuesChange={(params) => {
                if (params.keyword !== null && params.keyword?.length === 0) {
                  onFinish()
                }
              }}
            >
              <Space>
                <div>
                  <Form.Item
                    name="keyword"
                    label="キーワード検索"
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      allowClear={() => {
                        form.setFieldsValue({ keyword: null })
                        form.submit()
                      }}
                      style={{ width: 150, height: 37 }}
                      placeholder={"ID・タイトルなどを入力してください"}
                    />
                  </Form.Item>
                </div>

                <div>
                  <div style={{ display: "flex", justifyContent: "flex-end" }}>
                    <Button
                      style={{
                        width: 110,
                      }}
                      htmlType="submit"
                      icon={
                        <FilterOutlined
                          twoToneColor={process.env.PRIMARY_COLOR}
                        />
                      }
                      danger
                      className="!text-primary-90 !flex items-center !w-[100px] !h-[37px]"
                    >
                      {"絞り込む"}
                    </Button>
                  </div>
                </div>
              </Space>
            </Form>
          </Space>
          <div className="mt-4 pt-[10px]">
            <div
              style={{
                width: "100%",
              }}
            >
              <Spin spinning={loading}>
                {dataImage?.length > 0 && (
                  <div
                    id="scrollableDiv"
                    ref={ref}
                    style={{
                      height: 400,
                      overflow: "auto",
                      display: "flex",
                    }}
                  >
                    <InfiniteScroll
                      dataLength={dataImage?.length}
                      next={fetchMoreData}
                      style={{ minWidth: 700 }}
                      // inverse={true} //
                      height={390}
                      hasMore={hasMore}
                      loader={
                        <h4>
                          <Spin spinning={true} />
                        </h4>
                      }
                      scrollableTarget="scrollableDiv"
                    >
                      <div id="idTop"></div>
                      <div
                        style={{
                          display: "grid",
                          overflowX: "hidden",
                          gridTemplateColumns: "repeat(5, minmax(0, 1fr))",
                          gap: 12,
                          paddingTop: 20,
                          paddingRight: 20,
                        }}
                      >
                        {dataImage?.map((item, index) => (
                          <div
                            className="relative mb-5 flex flex-col items-center justify-center"
                            key={item.id}
                          >
                            <div className="relative min-h-[88px]  w-full">
                              {/* <LazyLoad height={90}> */}
                              <LazyLoadComponent>
                                <Image
                                  src={item.url || NoImg}
                                  className={`!rounded-md  w-full aspect-[14/9]`}
                                  style={{
                                    objectFit: "cover",
                                  }}
                                  loading="lazy"
                                  onError={({ currentTarget }) => {
                                    currentTarget.onerror = null // prevents looping
                                    currentTarget.src = NoImg
                                  }}
                                />
                                {/* </LazyLoad> */}
                              </LazyLoadComponent>
                              <img
                                style={{
                                  width: "36px",
                                  position: "absolute",
                                  top: "-17px",
                                  right: "-13px",
                                  cursor: "pointer",
                                }}
                                className="delete-img-btn"
                                src={close}
                                onClick={() =>
                                  setVisibleModal({
                                    status: true,
                                    item,
                                  })
                                }
                                alt={"error-img"}
                              />
                            </div>
                            <div className="max-w-[100px] flex items-center">
                              <Tooltip title={item?.metadata?.name}>
                                <Text className="line-clamp-1 px-2">
                                  {item?.metadata?.name || "なし"}
                                </Text>
                              </Tooltip>
                              <button
                                className="flex items-center"
                                onClick={() => {
                                  /* Copy the text inside the text field */
                                  setIsCopy((prev) => {
                                    const newObject = {}
                                    Object.keys(prev).forEach((item) => {
                                      newObject[item] = false
                                    })
                                    return {
                                      ...newObject,
                                      [index]: true,
                                    }
                                  })
                                  navigator.clipboard.writeText(
                                    item?.metadata?.name
                                  )
                                }}
                              >
                                {isCopy[index] ? (
                                  <CheckCircleTwoTone twoToneColor="#52c41a" />
                                ) : (
                                  <CopyTwoTone className="cursor-pointer" />
                                )}
                              </button>
                            </div>
                          </div>
                        ))}
                      </div>
                    </InfiniteScroll>
                  </div>
                )}
                {haveNoData && (
                  <Typography
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      margin: "auto",
                      // marginTop: '100px',
                    }}
                  >
                    表示する写真がありません
                  </Typography>
                )}
              </Spin>
            </div>
          </div>
        </div>
        <Modal
          title="この画像を削除してもよろしいでしょうか？"
          okText="削除"
          cancelText="キャンセル"
          centered
          visible={visibleModal.status}
          onOk={handleOkModalRemove}
          onCancel={() => setVisibleModal(initialVisitableModal)}
        />
      </Modal>
    </>
  )
}

export default ModalImage
